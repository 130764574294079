import React from 'react';
import {
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  NotificationBadge,
  NotificationBadgeVariant,
} from '@patternfly/react-core';

interface IHeaderToolbarProps {
  showAsCritical: boolean;
  unreadNumber: number;
  isDrawerExpanded: boolean;
  onDrawerToggle: Function;
}

export const HeaderToolbar = ({showAsCritical, unreadNumber, isDrawerExpanded, onDrawerToggle }: IHeaderToolbarProps) => {
  return (
    <Toolbar isFullHeight className={'justify-content-flex-end'}>
      <ToolbarContent>
        <ToolbarItem>
          <NotificationBadge
            variant={
              showAsCritical
                ? NotificationBadgeVariant.attention
                : unreadNumber === 0
                ? NotificationBadgeVariant.read
                : NotificationBadgeVariant.unread
            }
            onClick={(event) => onDrawerToggle(event)}
            isExpanded={isDrawerExpanded}
            count={unreadNumber}
          />
        </ToolbarItem>
      </ToolbarContent>
    </Toolbar>
  );
};
