import React from 'react';
import {
  Text,
  PageSection,
  TextContent,
  Level,
  Flex,
  LevelItem,
  Button,
  PageSectionVariants,
} from '@patternfly/react-core';
import { ExternalLinkAltIcon } from '@patternfly/react-icons';
import './ContentHeader.css'

interface IContentHeaderProps {
  icon: string;
  title: string;
  subTitle: string;
  buttonText: string;
  buttonLink: string;
}

export const ContentHeader = ({ icon, title, subTitle, buttonText, buttonLink }: IContentHeaderProps) => {
  return (
    <PageSection variant={PageSectionVariants.light}>
      <TextContent>
        <Level hasGutter>
          <Flex alignItems={{ default: 'alignItemsCenter' }}>
            <img src={icon} className={'app-header-icon'} alt="Content header application icon" />
            <Text component="h1" className={'pf-v5-u-font-size-3xl'}>{title}</Text>
          </Flex>
          <LevelItem>
            <Button
              isInline
              component="a"
              icon={<ExternalLinkAltIcon />}
              iconPosition="left"
              href={buttonLink}
              target="_blank"
            >
              {buttonText}
            </Button>
          </LevelItem>
        </Level>
        <Text component="p">{subTitle}</Text>
      </TextContent>
    </PageSection>
  );
};
