import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '@patternfly/react-core';
import { PageHeader } from '@patternfly/react-core/deprecated';
import { Sidebar } from './Sidebar';
import { HeaderToolbar } from './HeaderToolbar';
import { AAPNotificationDrawer } from './NotificationDrawer';
import { routesConfig } from '../routesConfig';
import { Announcement, AppURL } from '../api/types';
import { getAnnouncements, getAppURL } from '../api';
import { ContextProvider } from '../context';
import headerLogo from '../../images/logo_header.svg';

interface IAppLayout {
  children: React.ReactNode;
}

export const AppLayout = ({ children }: IAppLayout) => {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [isMobileView, setIsMobileView] = useState(true);
  const [isNavOpenMobile, setIsNavOpenMobile] = useState(false);
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [unreadNumber, setUnreadNumber] = useState(0);
  const [showAsCritical, setShowAsCritical] = useState(false);
  const [appURL, setAppURL] = useState<AppURL[]>();

  useEffect(() => {
    fetchAnnouncements();
    fetchAppUrl();
    // fetch announcement every min
    const intervalId = setInterval(fetchAnnouncements, 10000);
    // returning function to clear interval
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchAnnouncements = async () => {
    try {
      const data: Announcement[] = await getAnnouncements();
      setAnnouncements(data);
      setUnreadNumber(data && data.filter((announcement) => !announcement.acknowledged).length);
      setShowAsCritical(data && data.some((Announcement) => !Announcement.acknowledged && Announcement.level === 'critical'));
    } catch (error) {
      console.log('Could not fetch announcement data.', error);
    }
  };

  const fetchAppUrl = async () => {
    try {
      const data = await getAppURL();
      setAppURL(data);
    } catch (error) {
      console.log('Could not fetch application urls.', error);
    }
  };

  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView);
  };

  const onNavToggleMobile = () => {
    setIsNavOpenMobile(!isNavOpenMobile);
  };

  const onNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const onDrawerToggle = (_event) => {
    setIsDrawerExpanded(!isDrawerExpanded);
  };

  const onDrawerClose = () => {
    setIsDrawerExpanded(false);
  };

  function LogoImg() {
    const navigate = useNavigate();
    function handleClick() {
      navigate('/');
    }
    return (
      <img
        src={headerLogo}
        className={'header-logo'}
        onClick={handleClick}
        alt="Red Hat Ansible Automation Platform logo"
      />
    );
  }

  const NavSidebar = (
    <Sidebar
      isSidebarOpen={isMobileView ? isNavOpenMobile : isNavOpen}
      routesConfig={routesConfig}
      onSelect={onDrawerClose}
    />
  );

  const Toolbar = (
    <HeaderToolbar
      showAsCritical={showAsCritical}
      isDrawerExpanded={isDrawerExpanded}
      unreadNumber={unreadNumber}
      onDrawerToggle={onDrawerToggle}
    />
  );

  const Header = (
    <PageHeader
      logo={<LogoImg />}
      showNavToggle
      isNavOpen={isNavOpen}
      headerTools={Toolbar}
      onNavToggle={isMobileView ? onNavToggleMobile : onNavToggle}
    />
  );

  const NotificationDrawer = (
    <AAPNotificationDrawer
      announcements={announcements}
      unreadNumber={unreadNumber}
      fetchAnnouncements={fetchAnnouncements}
      onDrawerClose={onDrawerClose}
    />
  );

  return (
    <ContextProvider value={appURL}>
      <Page
        mainContainerId={'aap-welcome-page'}
        header={Header}
        sidebar={NavSidebar}
        notificationDrawer={NotificationDrawer}
        onPageResize={(_event, props: { mobileView: boolean; windowSize: number }) => onPageResize(props)}
        isNotificationDrawerExpanded={isDrawerExpanded}
      >
        {children}
      </Page>
    </ContextProvider>
  );
};
