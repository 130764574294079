import React, { useState, useEffect } from 'react';
import { ContentHeader } from '../Common/ContentHeader';
import { ResourceGallery } from '../Common/ResourceGallery';
import { Material, APP, APPLICATION_URL } from '../api/types';
import { getMaterialsByApp } from '../api';
import { useAppContext } from '../context';
import controllerIcon from '../../images/controller_icon.svg';

// eslint-disable-next-line prefer-const
export const Controller = () => {
  const [materials, setMaterials] = useState<Material[]>([]);
  const appUrl = useAppContext();
  const controllerUrl = appUrl && appUrl.filter((item) => item.name === APPLICATION_URL.CONTROLLER_URL)[0].url;

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    try {
      const data = await getMaterialsByApp(APP.CONTROLLER);
      setMaterials(data);
    } catch (error) {
      console.log('Could not fetch controller material data.', error);
    }
  };

  return (
    <>
      <ContentHeader
        icon={controllerIcon}
        title="Automation Controller"
        subTitle="Help teams manage complex multi-tier deployments by adding control, knowledge and delegation to Ansible-powered environments."
        buttonText="Launch Automation Controller"
        buttonLink={controllerUrl || ''}
      />
      {materials && <ResourceGallery materials={materials} />}
    </>
  );
};
