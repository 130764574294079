import React, { useState } from 'react';
import {
  NotificationDrawer,
  NotificationDrawerHeader,
  Dropdown,
  MenuToggleElement,
  MenuToggle,
  DropdownList,
  DropdownItem,
  NotificationDrawerBody,
  NotificationDrawerListItem,
  NotificationDrawerListItemHeader,
  NotificationDrawerListItemBody,
  NotificationDrawerList,
  Button,
  Text,
} from '@patternfly/react-core';
import ReactMarkdown from 'react-markdown';
import { EllipsisVIcon, ExternalLinkAltIcon } from '@patternfly/react-icons';
import { Announcement, ANNOUNCEMENT_DISPLAY_VARIANT } from '../api/types';
import { markAnnouncementAsRead, clearAnnouncement, clearAllAnnouncement, markAllAnnouncementAsRead } from '../api';

interface INotificationDrawerProps {
  announcements: Announcement[];
  unreadNumber: number;
  onDrawerClose: Function;
  fetchAnnouncements: Function;
}

// This function format a specific past time UTC string to a readable text of elapsed time from the provided past time to now 
export const getToNowTime = (pastTime: string) => {
  const diffInMilliseconds = Math.abs(new Date().getTime() - new Date(pastTime).getTime());
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
  } else {
    return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'} ago`;
  }
}

export const AAPNotificationDrawer = ({announcements, unreadNumber, onDrawerClose,fetchAnnouncements,}: INotificationDrawerProps) => {
  const [dropdownStates, setDropdownStates] = useState({});
  const timeCompare = (a: string, b: string) => new Date(b).getTime() - new Date(a).getTime();
  const unreadAnnouncements = announcements
    .filter((announcement) => !announcement.acknowledged)
    .sort((a, b) => timeCompare(a.publish, b.publish));
  const readAnnouncements = announcements
    .filter((announcement) => announcement.acknowledged)
    .sort((a, b) => timeCompare(a.publish, b.publish));
  const sortedAnnouncements = unreadAnnouncements.concat(readAnnouncements);

  const toggleDropdown = (dropdownId) => {
    setDropdownStates((prevState) => ({
      [dropdownId]: !prevState[dropdownId],
    }));
  };

  const onNotificationSelect = () => {
    setDropdownStates({});
  };

  const markAllRead = () => {
    markAllAnnouncementAsRead();
    fetchAnnouncements();
  };

  const clearAll = () => {
    clearAllAnnouncement();
    fetchAnnouncements();
  };

  const clear = async (id) => {
    await clearAnnouncement(id);
    fetchAnnouncements();
  };

  const markAsRead = async (id) => {
    await markAnnouncementAsRead(id);
    fetchAnnouncements();
  };

  return (
    <NotificationDrawer>
      <NotificationDrawerHeader count={unreadNumber} onClose={() => onDrawerClose()}>
        <Dropdown
          onSelect={onNotificationSelect}
          isOpen={!!dropdownStates['global']}
          popperProps={{ position: 'right' }}
          onOpenChange={(isOpen) => !isOpen && setDropdownStates({})}
          toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
            <MenuToggle
              ref={toggleRef}
              isExpanded={dropdownStates['global']}
              onClick={() => toggleDropdown('global')}
              variant="plain"
            >
              <EllipsisVIcon aria-hidden="true" />
            </MenuToggle>
          )}
        >
          <DropdownList>
            <DropdownItem key="markAllRead" onClick={markAllRead}>
              Mark all read
            </DropdownItem>
            <DropdownItem key="clearAll" onClick={clearAll}>
              Clear all
            </DropdownItem>
          </DropdownList>
        </Dropdown>
      </NotificationDrawerHeader>
      <NotificationDrawerBody>
        <NotificationDrawerList>
          {announcements &&
            sortedAnnouncements.map((announcement, index) => (
              <NotificationDrawerListItem
                variant={ANNOUNCEMENT_DISPLAY_VARIANT[announcement.level]}
                key={index}
                isRead={announcement.acknowledged}
              >
                <NotificationDrawerListItemHeader
                  variant={ANNOUNCEMENT_DISPLAY_VARIANT[announcement.level]}
                  title={announcement.name}
                >
                  <Dropdown
                    onSelect={onNotificationSelect}
                    isOpen={!!dropdownStates[announcement.id]}
                    onOpenChange={(isOpen) => !isOpen && setDropdownStates({})}
                    popperProps={{ position: 'right' }}
                    toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                      <MenuToggle
                        ref={toggleRef}
                        isExpanded={!!dropdownStates[announcement.id]}
                        onClick={() => toggleDropdown(announcement.id)}
                        variant="plain"
                      >
                        <EllipsisVIcon aria-hidden="true" />
                      </MenuToggle>
                    )}
                  >
                    <DropdownList>
                      <DropdownItem
                        key="markRead"
                        onClick={() => markAsRead(announcement.id)}
                        isDisabled={announcement.acknowledged}
                      >
                        Mark as read
                      </DropdownItem>
                      <DropdownItem key="clear" onClick={() => clear(announcement.id)}>
                        Clear
                      </DropdownItem>
                    </DropdownList>
                  </Dropdown>
                </NotificationDrawerListItemHeader>
                <NotificationDrawerListItemBody timestamp={announcement.publish && getToNowTime(announcement.publish)}>
                  <ReactMarkdown
                    children={announcement.body}
                    components={{
                      a: ({href, children}) => <Button component='a' variant='link' isInline={true} href={href} icon={<ExternalLinkAltIcon />} iconPosition="right" target="_blank">{children[0]}</Button>,
                      p: ({children}) => <Text>{children}</Text>
                    }}
                  />
                </NotificationDrawerListItemBody>
              </NotificationDrawerListItem>
            ))}
        </NotificationDrawerList>
      </NotificationDrawerBody>
    </NotificationDrawer>
  );
};
