import React from 'react';
import { Card, CardBody, CardTitle, Title, Flex, Divider } from '@patternfly/react-core';
import { Material } from 'app/api/types';
import { MarkdownContentCard } from 'app/Common/MarkdownContentCard';
import './ResourcesCard.css'

interface IResourcesCardProps {
  resources: Material[];
}

export const ResourcesCard = ({ resources }: IResourcesCardProps) => {
  return (
    // only render card when there is data for it
    (resources && resources.length > 0) ?
    <Card>
      <CardTitle>
        <Title headingLevel="h3" size="md">
          Additional Resources
        </Title>
      </CardTitle>
      <CardBody>
        <Flex>
          {resources.sort((a, b) => a.order - b.order).map((resource, index) => (
            <React.Fragment key={index}>
              <Flex alignSelf={{ default: 'alignSelfStretch' }} flex={{ default: 'flex_1' }}>
                <MarkdownContentCard content={resource.body} isPlain={true} />
              </Flex>
              {index !== resources.length - 1 ? (
                <Divider
                  inset={{ default: 'insetMd' }}
                  orientation={{
                    default: 'vertical',
                  }}
                />
              ) : null}
            </React.Fragment>
          ))}
        </Flex>
      </CardBody>
    </Card>
    : <></>
  );
};
