import { createContext, useContext } from 'react';
import { AppURL } from '../app/api/types';

// Create a context to manage fetched AppURL that share across multiple components
const Context = createContext<AppURL[] | undefined>(undefined);

export const ContextProvider = Context.Provider;

// Create a custom hook for using the context
export const useAppContext = () => {
  return useContext(Context);
};
