import React from 'react';
import { PageSidebar, PageSidebarBody, Nav, NavList, NavItem, NavExpandable, Icon } from '@patternfly/react-core';
import { NavLink, useLocation } from 'react-router-dom';
import { Text, Bullseye } from '@patternfly/react-core';
import { IAppRoute, IAppRouteGroup, AppRouteConfig } from '../routesConfig';
import { ExternalLinkAltIcon } from '@patternfly/react-icons';
import aapIcon from './../../images/aap_icon.svg';

interface ISidebarProps {
  routesConfig: AppRouteConfig[];
  isSidebarOpen: boolean;
  onSelect: () => void;
}

export const Sidebar = ({ routesConfig, isSidebarOpen, onSelect }: ISidebarProps) => {
  const location = useLocation();

  const renderNavItem = (route: IAppRoute, index: number) => (
    <NavItem key={`${route.label}-${index}`} id={`${route.label}-${index}`} isActive={route.path === location.pathname}>
      <NavLink to={route.path}>{route.label}</NavLink>
    </NavItem>
  );

  const renderNavGroup = (group: IAppRouteGroup, groupIndex: number) => (
    <NavExpandable
      key={`${group.label}-${groupIndex}`}
      id={`${group.label}-${groupIndex}`}
      title={group.label}
      isActive={group.routes.some((route) => route.path === location.pathname)}
    >
      {group.routes.map((route, idx) => route.label && renderNavItem(route, idx))}
    </NavExpandable>
  );

  const Navigation = (
    <Nav id="nav-primary-simple" theme="dark" onSelect={onSelect}>
      <Bullseye className={'pf-v5-u-pt-md pf-v5-u-pb-sm'}>
        <img src={aapIcon} className={'nav-banner-logo'} alt="Sidebar icon" />
        <Text className={'pf-v5-u-font-size-md pf-v5-u-color-light-100'}>Ansible Automation Platform</Text>
      </Bullseye>
      <NavList id="nav-list-simple">
        {routesConfig.map(
          (route, idx) => route.label && (!route.routes ? renderNavItem(route, idx) : renderNavGroup(route, idx))
        )}
        <NavItem to="https://access.redhat.com/documentation/en-us/ansible_on_clouds/2.x/html/red_hat_ansible_automation_platform_on_microsoft_azure_guide/index" target="_blank">
          Documentation{' '}
          <Icon size="sm" className={'pf-v5-u-ml-sm'}>
            <ExternalLinkAltIcon />
          </Icon>
        </NavItem>
      </NavList>
    </Nav>
  );

  return (
    <PageSidebar theme="dark" isSidebarOpen={Boolean(isSidebarOpen)}>
      <PageSidebarBody>{Navigation}</PageSidebarBody>
    </PageSidebar>
  );
};
