import React from 'react';
import { Overview } from './Overview';
import { Controller } from './Controller';
import { Hub } from './Hub';
import { Analytics } from './Analytics';

export interface IAppRoute {
  label?: string; // Excluding the label will exclude the route from the nav sidebar in AppLayout
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.FunctionComponent;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  exact?: boolean;
  path: string;
  title?: string;
  isAsync?: boolean;
  routes?: undefined;
}

export interface IAppRouteGroup {
  label: string;
  routes: IAppRoute[];
}

export type AppRouteConfig = IAppRoute | IAppRouteGroup;

const routesConfig: AppRouteConfig[] = [
  {
    component: Overview,
    path: '/',
    label: 'Overview',
    title: 'Overview',
  },
  {
    component: Controller,
    path: '/controller',
    label: 'Automation Controller',
    title: 'Automation Controller',
  },
  {
    component: Hub,
    path: '/hub',
    label: 'Automation Hub',
    title: 'Automation Hub',
  },
  // Commented out as EDA is not in the scope of phase 1
  // {
  //   component: EDA,
  //   path: '/eda',
  //   label: 'Events-Driven Ansible Controller',
  //   title: 'Events-Driven Ansible Controller',
  // },
  {
    component: Analytics,
    path: '/analytics',
    label: 'Automation Analytics',
    title: 'Automation Analytics',
  },
  {
    component: Overview,
    path: '*',
  },
];

export { routesConfig };
