import { AutomationNodesUsage, AppURL, Material, APP, Announcement } from './types';

const baseURI = '/api';

export function getNodesUsage(): Promise<AutomationNodesUsage> {
  return fetch(`${baseURI}/usage`)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }
      throw new Error(`Unexpected response with status code: ${resp.status}`);
    })
    .then((data) => {
      return new AutomationNodesUsage(data);
    });
}

export function getMaterialsByApp(app: APP): Promise<Material[]> {
  return fetch(`${baseURI}/content?target=${app}`)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }
      throw new Error(`Unexpected response with status code: ${resp.status}`);
    })
    .then((data) => {
      return data as Material[];
    });
}

export function getAnnouncements(): Promise<Announcement[]> {
  return fetch(`${baseURI}/announcement`)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }
      throw new Error(`Unexpected response with status code: ${resp.status}`);
    })
    .then((data) => {
      return data as Announcement[];
    });
}

export function markAnnouncementAsRead(id): Promise<boolean> {
  return fetch(`${baseURI}/acknowledge/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.ok);
}

export function markAllAnnouncementAsRead(): Promise<boolean> {
  return fetch(`${baseURI}/acknowledge/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.ok);
}

export function clearAnnouncement(id): Promise<boolean> {
  return fetch(`${baseURI}/clear/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.ok);
}

export function clearAllAnnouncement(): Promise<boolean> {
  return fetch(`${baseURI}/clear/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((resp) => resp.ok);
}

export function getAppURL(): Promise<AppURL[]> {
  return fetch(`${baseURI}/url`)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }
      throw new Error(`Unexpected response with status code: ${resp.status}`);
    })
    .then((data) => {
      return data as AppURL[];
    });
}
