import React, { useEffect, useState } from 'react';
import { ContentHeader } from '../Common/ContentHeader';
import { ResourceGallery } from '../Common/ResourceGallery';
import { Material, APP } from '../api/types';
import { getMaterialsByApp } from '../api';
import analyticsIcon from '../../images/analytics_icon.svg';

export const Analytics = () => {
  const [materials, setMaterials] = useState<Material[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getMaterialsByApp(APP.ANALYTICS);
      setMaterials(data);
    } catch (error) {
      console.log('Could not fetch analytics materials data.', error);
    }
  };

  return (
    <>
      <ContentHeader
        icon={analyticsIcon}
        title="Automation Analytics"
        subTitle="Gain insights into your deployments through visual dashboards and organization statistics, calculate your return on investment, and explore automation processes details."
        buttonText="Launch Automation Analytics"
        buttonLink="https://console.redhat.com/ansible/ansible-dashboard"
      />
      <ResourceGallery materials={materials} />
    </>
  );
};
