import React from 'react';
import { Card, CardBody, Text, CardTitle, Button, GridItem, CardFooter } from '@patternfly/react-core';
import { ExternalLinkAltIcon } from '@patternfly/react-icons';

interface IAppCardProps {
  apps: {
    icon: string;
    title: string;
    desc: string;
    appUrl: {
      name: string;
      url: string;
    };
    path: string;
  }[];
}

export const AppCard = ({ apps }: IAppCardProps) => {
  return (
    <>
      {apps.map((app, index) => (
        <GridItem span={4} key={index}>
          <Card isFullHeight>
            <CardTitle>
              <img src={app.icon} className={'app-card-icon'} alt="Application card icon" />
              <Button component="a" isInline variant="link" href={app.path} target="_self">
                {app.title}
              </Button>
            </CardTitle>
            <CardBody className={'app-card-body'}>
              <Text>{app.desc}</Text>
            </CardBody>
            <CardFooter>
              <Button
                component="a"
                isInline
                variant="link"
                href={app.appUrl.url}
                icon={<ExternalLinkAltIcon />}
                iconPosition="right"
                target="_blank"
              >
                Launch {app.title}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      ))}
    </>
  );
};
