import React, { useEffect, useState } from 'react';
import { ContentHeader } from '../Common/ContentHeader';
import { ResourceGallery } from '../Common/ResourceGallery';
import { Material, APP, APPLICATION_URL } from '../api/types';
import { getMaterialsByApp } from '../api';
import { useAppContext } from '../context';
import hubIcon from '../../images/hub_icon.svg';

export const Hub = () => {
  const [materials, setMaterials] = useState<Material[]>([]);
  const appUrl = useAppContext();
  const hubUrl = appUrl && appUrl.filter((item) => item.name === APPLICATION_URL.HUB_URL)[0].url;

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    try {
      const data = await getMaterialsByApp(APP.HUB);
      setMaterials(data);
    } catch (error) {
      console.log('Could not fetch hub materials data.', error);
    }
  };

  return (
    <>
      <ContentHeader
        icon={hubIcon}
        title="Automation Hub"
        subTitle="Find and use content that is supported by Red Hat and our partners to deliver reassurance for the most demanding environments."
        buttonText="Launch Automation Hub"
        buttonLink={hubUrl || ''}
      />
      <ResourceGallery materials={materials} />
    </>
  );
};
