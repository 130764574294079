import React, { useState, useEffect } from 'react';
import {
  Grid,
  PageSection,
  PageSectionVariants,
  Text,
  TextContent,
  Level,
  LevelItem,
} from '@patternfly/react-core';
import { ResourcesCard } from './ResourcesCard';
import { AppCard } from './AppCard';
import { getMaterialsByApp } from '../api';
import { appList } from '../api/static';
import { Material, APP } from '../api/types';
import { useAppContext } from '../context';

export const Overview = () => {
  const [materials, setMaterials] = useState<Material[]>([]);
  // Commented out because manage modal is not being shown in phase 1
  // const [isModalOpen, setIsModalOpen] = React.useState(false);
  const appUrl = useAppContext();

  // assign fetched app url to static app list
  // eslint-disable-next-line
  appList.map((item) => {
    const matchedAppUrl = appUrl && appUrl.filter((appUrl) => appUrl.name === item.appUrl.name);
    if (matchedAppUrl && matchedAppUrl.length > 0) {
      item.appUrl.url = matchedAppUrl[0].url;
    }
  });

  // Commented out because manage modal is not being shown in phase 1
  // const handleModalToggle = () => {
  //   setIsModalOpen(!isModalOpen);
  // };

  // const navToSupport = () => {
  //   setIsModalOpen(!isModalOpen);
  //   window.open('https://access.redhat.com/support/', '_blank');
  // };

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    try {
      const data = await getMaterialsByApp(APP.OVERVIEW);
      setMaterials(data);
    } catch (error) {
      console.log('Could not fetch overview materials data.', error);
    }
  };

  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1" className={'pf-v5-u-font-size-3xl'}>Welcome to Ansible Automation Platform on Azure</Text>
          <Level hasGutter>
            <LevelItem>
              <Text component="p">
                Connect intelligence, analytics and service requests to enable more responsive and resilient automation.
              </Text>
            </LevelItem>
            {/* Commented out because manage modal is not being shown in phase 1 */}
            {/* <LevelItem>
              <Button isInline variant="link" icon={<CogIcon />} iconPosition="left" onClick={handleModalToggle}>
                Manage view
              </Button>
            </LevelItem> */}
          </Level>
        </TextContent>
      </PageSection>
      <PageSection>
        <Grid hasGutter>
          <AppCard apps={appList} />
          <ResourcesCard resources={materials} />
        </Grid>
      </PageSection>
      {/* Commented out because manage modal is not being shown in phase 1 */}
      {/* <Modal
        variant={ModalVariant.small}
        title="Manage view"
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        actions={[
          <Button
            key="confirm"
            variant="primary"
            icon={
              <Icon size="sm">
                <ExternalLinkAltIcon />
              </Icon>
            }
            iconPosition="left"
            onClick={navToSupport}
          >
            Create ticket
          </Button>,
          <Button key="close" variant="link" onClick={handleModalToggle}>
            Close
          </Button>,
        ]}
      >
        To disable your Ansible Automation Platform Welcome page on Azure, submit a ticket for SRE to perform the
        disable.
      </Modal> */}
    </>
  );
};
