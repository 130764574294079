import hubIcon from './../../images/hub_icon.svg';
import analyticsIcon from './../../images/analytics_icon.svg';
import controllerIcon from './../../images/controller_icon.svg';
import { APPLICATION_URL } from '../api/types';

export const appList = [
  {
    title: 'Automation Controller',
    desc: 'Help teams manage complex multi-tier deployments by adding control, knowledge, and delegation to Ansible-powered environments.',
    appUrl: {
      name: APPLICATION_URL.CONTROLLER_URL,
      url: '',
    },
    icon: controllerIcon,
    path: '/controller',
  },
  {
    title: 'Automation Hub',
    desc: 'Find and use content that is supported by Red Hat and our partners to deliver reassurance for the most demanding environments.',
    appUrl: {
      name: APPLICATION_URL.HUB_URL,
      url: '',
    },
    link: '',
    icon: hubIcon,
    path: '/hub',
  },
  // Commented out as EDA is not in the scope of phase 1
  // {
  //   title: 'Events-Driven Ansible Controller',
  //   desc: 'Allows systems and IT teams to initiate a predefined automated response when an event occurs. It connects data, analytics, and service requests to automate actions so that activities can take place in a single, rapid motion.',
  //   appUrl: {
  //     name: APPLICATION_URL.EDA_URL,
  //     url: '',
  //   },
  //   link: '',
  //   icon: edaIcon,
  //   path: '/eda',
  // },
  {
    title: 'Automation Analytics',
    desc: 'Gain insights into your deployments through visual dashboards and organization statistics, calculate your return on investment, and explore automation processes details.',
    appUrl: {
      name: '',
      url: 'https://console.redhat.com/ansible/ansible-dashboard',
    },
    link: 'https://console.redhat.com/ansible/ansible-dashboard',
    icon: analyticsIcon,
    path: '/analytics',
  },
];
