export const enum APP {
  CONTROLLER = 'controller',
  HUB = 'hub',
  ANALYTICS = 'analytics',
  OVERVIEW = 'overview',
  EDA = 'eda',
}

export const enum APPLICATION_URL {
  CONTROLLER_URL = 'CONTROLLER_URL',
  HUB_URL = 'HUB_URL',
  SSO_URL = 'SSO_URL',
  EDA_URL = 'EDA_URL',
}

export const ANNOUNCEMENT_DISPLAY_VARIANT = {
  info: 'info',
  critical: 'danger',
};

export class AutomationNodesUsage {
  totalNodes: number = -1;
  usedNodes: number = -1;
  billingStartDate: Date | undefined;
  billingEndDate: Date | undefined;
  billingCycleRange: string = '';
  billingCycleDaysLeft: number = -1;
  constructor(automationNodesUsage) {
    const formatter = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: 'numeric' });
    if (automationNodesUsage) {
      this.totalNodes = automationNodesUsage.included;
      this.usedNodes = automationNodesUsage.used;
      this.billingStartDate = new Date(automationNodesUsage.periodstart);
      this.billingEndDate = new Date(automationNodesUsage.periodend);
      this.billingCycleRange = formatter.formatRange(this.billingStartDate, this.billingEndDate);
      // using Math.round() to get around issues with DST
      this.billingCycleDaysLeft = Math.round(
        (this.billingEndDate.getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000)
      );
    }
  }
}

export class AppURL {
  name: string = '';
  url: string = '';
  constructor(appURL) {
    if (appURL) {
      this.name = appURL.name;
      this.url = appURL.url;
    }
  }
}

export class Material {
  name: string = '';
  body: string = '';
  target: string = '';
  order: number = -1;
  constructor(material) {
    if (material) {
      this.name = material.title;
      this.body = material.desc;
      this.target = material.app;
      this.order =  material.order;
    }
  }
}

export class Announcement {
  id: string = '';
  name: string = '';
  level: string = '';
  publish: string = '';
  repeat: string = '';
  body: string = '';
  region: string = '';
  subscription: string = '';
  acknowledged: boolean = false;
  constructor(announcement) {
    if (announcement) {
      this.id = announcement.id;
      this.name = announcement.name;
      this.level = announcement.level;
      this.publish = announcement.publish;
      this.repeat = announcement.repeat;
      this.body = announcement.body;
      this.region = announcement.region;
      this.subscription = announcement.subscription;
      this.acknowledged = announcement.acknowledged;
    }
  }
}
