import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppLayout } from './AppLayout';
import { AppRoutes } from './AppRoutes/index';
import '@patternfly/react-core/dist/styles/base.css';
import '@patternfly/react-styles/css/utilities/Spacing/spacing.css';
import '@patternfly/react-styles/css/utilities/Text/text.css';
import './app.css';

const App = () => (
  <BrowserRouter>
    <AppLayout>
      <AppRoutes />
    </AppLayout>
  </BrowserRouter>
);

export default App;
