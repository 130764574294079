import React from 'react';
import { PageSection, Grid, GridItem } from '@patternfly/react-core';
import { Material } from '../api/types';
import { MarkdownContentCard } from 'app/Common/MarkdownContentCard';

interface IResourceGalleryProps {
  materials: Material[];
}

export const ResourceGallery = ({ materials }: IResourceGalleryProps) => {
  return (
    <PageSection>
      <Grid hasGutter data-testid="resource-gallery">
        {materials.sort((a, b) => a.order - b.order).map((material, index) => (
          <GridItem span={4} key={index}>
            <MarkdownContentCard content={material.body} isFlat={true} isFullHeight={true}/>
          </GridItem>
        ))}
      </Grid>
    </PageSection>
  );
};
