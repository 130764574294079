import React from 'react';
import { Button, Card, CardBody, CardTitle, Label } from '@patternfly/react-core';
import { ExternalLinkAltIcon } from '@patternfly/react-icons';
import ReactMarkdown from 'react-markdown';
import './MarkdownContentCard.css'

interface ICardContent {
  content: string
  isFlat?: boolean | undefined
  isPlain?: boolean | undefined
  isFullHeight?: boolean | undefined
}

export const MarkdownContentCard = (cardContent: ICardContent) => {
  return (
    <Card className='markdown-card'
      isFlat=      {cardContent.isFlat !== undefined        ? cardContent.isFlat:       false}
      isPlain=     {cardContent.isPlain !== undefined       ? cardContent.isPlain:      false}
      isFullHeight={cardContent.isFullHeight !== undefined  ? cardContent.isFullHeight: false} >
      {/* { cardContent.title && <CardTitle>{cardContent.title}</CardTitle> } */}

      <ReactMarkdown
        children={cardContent.content}
        components={{
          a: ({href, children}) => <Button component='a' variant='link' isInline={true} href={href} icon={<ExternalLinkAltIcon />} iconPosition="right" target="_blank">{children[0]}</Button>,
          h1: ({children}) => <CardTitle>{children[0]}</CardTitle>,
          h5: ({children}) => <CardBody><Label className='pf-v5-u-font-size-xs pf-v5-u-mr-sm pf-v5-u-mb-lg'>{children[0]}</Label></CardBody>,
          p: ({children}) => <CardBody >{children}</CardBody>
        }}
      />

    </Card>
  )
}
